import clsx from "clsx";
import { changeLanguage } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <header className="flex items-center justify-between">
      <Link to="/">
        <img src="/image/logo.png" width="120" height="61" />
      </Link>
      <DesktopNav />
      <MobileNav />
      <LanguageChanger />
    </header>
  );
}

function LanguageChanger() {
  return (
    <section>
      <button
        className="btn btn-ghost text-xl text-red-700"
        onClick={() => {
          changeLanguage("zh");
          localStorage.setItem("language", "zh");
        }}
      >
        繁
      </button>
      <button
        className="btn btn-ghost text-xl text-red-700"
        onClick={() => {
          changeLanguage("cn");
          localStorage.setItem("language", "cn");
        }}
      >
        简
      </button>
      <button
        className="btn btn-ghost text-xl text-red-700"
        onClick={() => {
          changeLanguage("en");
          localStorage.setItem("language", "en");
        }}
      >
        Eng
      </button>
    </section>
  );
}

function DesktopNav() {
  const { t } = useTranslation();
  const [currDropdownId, setCurrDropdownId] = useState(-1);

  return (
    <nav className="hidden items-center gap-3 md:flex">
      <NavDropdown
        id={0}
        currDropdownId={currDropdownId}
        onOpen={(id) => setCurrDropdownId(id)}
        text={t("about")}
        links={[
          {
            text: t("about"),
            href: "/aboutus",
          },
          {
            text: t("latestNews"),
            href: "/latest_news",
          },
          {
            text: t("contactus"),
            href: "/contactus",
          },
        ]}
      />
      <NavDropdown
        id={1}
        currDropdownId={currDropdownId}
        onOpen={(id) => setCurrDropdownId(id)}
        text={t("services")}
        links={[
          {
            text: t("careerTraining"),
            href: "/career_training",
          },
          {
            text: t("subsidy"),
            href: "/funding_methods",
          },
          {
            text: t("cooperation"),
            href: "/cooperation",
          },
          {
            text: t("partnershipDevelopment"),
            href: "/relationship",
          },
          {
            text: t("starting a business"),
            href: "/career",
          },
        ]}
      />
      <NavLink href={"/our_partner"} text={t("partnership")} />
      <NavDropdown
        id={2}
        currDropdownId={currDropdownId}
        onOpen={(id) => setCurrDropdownId(id)}
        text={t("activities")}
        links={[
          {
            text: t("activityCategory"),
            href: "/activity_category",
          },
          {
            text: t("latestActivity"),
            href: "/latest_activity",
          },
          {
            text: t("activityReview"),
            href: "/past_activity",
          },
          {
            text: t("gallery"),
            href: "/activity_gallery",
          },
          {
            text: t("future"),
            href: "/future_activity",
          },
        ]}
      />
    </nav>
  );
}

function NavLink({ href, text, className }: { href: string; text: string; className?: string }) {
  return (
    <Link className={clsx("btn btn-ghost text-xl text-red-700", className)} to={href}>
      {text}
    </Link>
  );
}

function NavDropdown({ id, text, links, currDropdownId, onOpen }: { id: number; text: string; links?: { text: string; href: string }[]; currDropdownId: number; onOpen: (id: number) => void }) {
  return (
    <details
      className="dropdown"
      open={currDropdownId === id}
      onClick={(e) => {
        e.preventDefault();
        onOpen(id === currDropdownId ? -1 : id);
      }}
    >
      <summary className="btn btn-ghost w-full text-xl text-red-700">{text}</summary>
      <ul className="menu dropdown-content z-[1] rounded-box bg-base-100 p-2 shadow">
        {links?.map((l, idx) => (
          <li key={idx}>
            <NavLink href={l.href} text={l.text} className="w-max min-w-full" />
          </li>
        ))}
      </ul>
    </details>
  );
}

function MobileNav() {
  const { t } = useTranslation();

  return (
    <details className="dropdown ml-auto md:hidden">
      <summary className="btn btn-circle btn-ghost">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#b91c1c">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </summary>
      <ul className="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 text-red-700 shadow">
        <li>
          <Link to="/aboutus">{t("about")}</Link>
        </li>
        <li>
          <Link to="/latest_news">{t("latestNews")}</Link>
        </li>
        <li>
          <Link to="/career_training">{t("careerTraining")}</Link>
        </li>
        <li>
          <Link to="/funding_methods">{t("subsidy")}</Link>
        </li>
        <li>
          <Link to="/cooperation">{t("cooperation")}</Link>
        </li>
        <li>
          <Link to="/relationship">{t("partnershipDevelopment")}</Link>
        </li>
        <li>
          <Link to="/career">{t("starting a business")}</Link>
        </li>
        <li>
          <Link to="/our_partner">{t("partnership")}</Link>
        </li>
        <li>
          <Link to="/activity_category">{t("activityCategory")}</Link>
        </li>
        <li>
          <Link to="/latest_activity">{t("latestActivity")}</Link>
        </li>
        <li>
          <Link to="/past_activity">{t("activityReview")}</Link>
        </li>
        <li>
          <Link to="/activity_gallery">{t("gallery")}</Link>
        </li>
        <li>
          <Link to="/future_activity">{t("futureActivity")}</Link>
        </li>
      </ul>
    </details>
  );
}
